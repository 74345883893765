import React, { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { ContactInfoSelectionMode } from './ContactInfoSelection';
import Login from './Login';
import Register, { RegisterMode } from './Register';

interface Props { }

const UserAuthentication: React.FunctionComponent<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const [tabMode, setTabMode] = useState<string>('login');
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ? process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY : "";

    const onModeChange = (mode: ContactInfoSelectionMode) => {
        //do nothing
    }

    const handleRegisterSuccess = (message: string) => {
        setSuccessMessage(message);
        setTabMode('login');
    };

    return (
        <div className="row">
            <div className="col-12">
                <h1>{t("order.userAuthentication.authenticationTitle")}</h1>
                <h5 className="mb-4">{t("order.userAuthentication.authenticationInfo")}</h5>

                {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}

                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3 nav-justified"
                    activeKey={tabMode}
                    onSelect={(x) => setTabMode(x == null ? "" : x.toString())}
                    variant="tabs"
                >
                    <Tab className='tab' eventKey="login" title={t("order.userAuthentication.loginTabTitle")}>
                        {tabMode == "login" ? <Login reCaptchaKey={SITE_KEY} /> : null}
                    </Tab>
                    <Tab className='tab' eventKey="register" title={t("order.userAuthentication.registerTabTitle")}>
                        {tabMode == "register" ? <Register reCaptchaKey={SITE_KEY} onModeChange={onModeChange} registerMode={RegisterMode.EntirelyNewAccount} contactInfo={null} onRegisterSuccess={handleRegisterSuccess} /> : null}
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

export default UserAuthentication;