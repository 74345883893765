import React, { useEffect, useRef, useState } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha";
import AuthDataService from "../../services/auth.dataservice";
import { useTranslation } from 'react-i18next';
import OrderBackButton from './OrderBackButton';
import { CarouselStep } from '../../utils/CarouselSteps';
import { useFormik } from 'formik';
import { updateOrder } from '../../store/slices/orderSlice';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { AuthenticateRequest, defaultAuthenticateRequest } from '../../models/AuthenticateRequest';
import { hasAnyFormErrors, validateLoginForm } from '../../utils/formValidation';
import { setError } from '../../store/slices/errorSlice';
import { AuthenticateReponse } from '../../models/AuthenticateResponse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

interface Props {
    reCaptchaKey: string
}

const Login: React.FunctionComponent<Props> = ({ reCaptchaKey }: Props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const order = useAppSelector((rootState) => rootState.order);
    const [captchaToken, setCaptchaToken] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasJwtToken, setHasJwtToken] = useState<boolean>(false);
    const [tan, setTan] = useState<string>("");
    const captchaRef = useRef<ReCAPTCHA>(null);

    const formik = useFormik<AuthenticateRequest>({
        initialValues: defaultAuthenticateRequest,
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        onSubmit: (formValues, { setErrors }) => {
            const errors = validateLoginForm(formValues);
            if (hasAnyFormErrors(errors)) {
                setErrors(errors);
                return;
            }
            dispatch(updateOrder({
                ...order,
                user: null
            }));
            setIsLoading(true);
            AuthDataService.authenticate({ ...formValues, captchaToken }, i18n.language).then((response) => {
                if (!response.hasError) {
                    if (!response.jwtToken) {
                        setHasJwtToken(true);
                        setTan("");
                    } else {
                        localStorage.setItem("order-esg2go-user", response.jwtToken);
                        updateOrderUser(response, CarouselStep.ContactInfoSelection);
                    }
                    setErrorMessage("");
                    setIsLoading(false);
                } else {
                    setErrorMessage(response.message);
                    captchaRef.current?.reset();
                    setIsLoading(false);
                }
                dispatch(setError(""));
            }).catch((e: Error) => {
                dispatch(setError(t("misc.genericError")));
                captchaRef.current?.reset();
                setIsLoading(false);
            });
        },
    });

    const updateOrderUser = (authenticateReponse: AuthenticateReponse, carouselIndex: CarouselStep | null) => {
        dispatch(updateOrder({
            ...order,
            user: { id: authenticateReponse.id, email: authenticateReponse.email, jwtToken: authenticateReponse.jwtToken },
            carouselIndex: carouselIndex ? carouselIndex : order.carouselIndex
        }));
    }

    const checkTan = () => {
        if (tan && hasJwtToken && formik.values) {
            setIsLoading(true);
            AuthDataService.checkTan({ tan, email: formik.values.email }).then((response) => {
                if (!response.hasError) {
                    updateOrderUser(response, CarouselStep.ContactInfoSelection);               
                    setErrorMessage("");
                    setIsLoading(false);
                } else {
                    setErrorMessage(response.message);
                    setIsLoading(false);
                }
                dispatch(setError(""));
            }).catch((e: Error) => {
                dispatch(setError(t("misc.genericError")));
                setIsLoading(false);
            });
        }
    }

    return (
        <>
            {!hasJwtToken ?
                // Login
                <>
                    <h3 className='mb-3'>{t("misc.login")}</h3>
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Label className='mt-3'>{t("order.userAuthentication.email")}</Form.Label>
                        <Form.Control name="email" onChange={formik.handleChange} value={formik.values.email} className={formik.errors.email ? "is-invalid" : ""} />
                        {formik.errors.email ? <Form.Control.Feedback type="invalid">{t(formik.errors.email)}</Form.Control.Feedback> : null}

                        <ReCAPTCHA
                            key={i18n.language.toLowerCase()}
                            hl={i18n.language.toLowerCase()}
                            className="mt-4"
                            sitekey={reCaptchaKey}
                            ref={captchaRef}
                            onExpired={() => { captchaRef.current?.reset(); }}
                            onChange={(t: string | null) => { if (t != null) setCaptchaToken(t); }}
                        />

                        {
                            errorMessage ?
                                <div className="mt-4 error-display">
                                    <Form.Control
                                        className="is-invalid"
                                        type="hidden" />
                                    <Form.Control.Feedback type="invalid" dangerouslySetInnerHTML={{ __html: t(errorMessage) }}></Form.Control.Feedback>
                                </div> : null
                        }

                        <OrderBackButton toIndex={CarouselStep.ReferenceYear} />
                        <Button type='submit' variant="primary" className="mt-4" disabled={isLoading} >
                            {t("misc.login")}
                            {isLoading ?
                                <span> <Spinner
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /></span> : null
                            }
                        </Button>
                    </Form>
                </>

                :

                // Enter TAN
                <>
                    <h3 className='mb-3'>{t("order.userAuthentication.tanTitle")}</h3>
                    <h5 className='mb-2'>{t("order.userAuthentication.tanText")}</h5>
                    <Form.Control name="tan" value={tan} className="w-50" onChange={(e) => setTan(e.target.value)}></Form.Control>

                    {
                        errorMessage ?
                            <div className="mt-4 error-display">
                                <Form.Control
                                    className="is-invalid"
                                    type="hidden" />
                                <Form.Control.Feedback type="invalid" dangerouslySetInnerHTML={{ __html: t(errorMessage) }}></Form.Control.Feedback>
                            </div> : null
                    }

                    <Button variant="danger" onClick={() => setHasJwtToken(false)} className="mt-4 me-3">
                        <FontAwesomeIcon icon={faChevronLeft} /> {t("misc.back")}
                    </Button>
                    <Button variant="primary" className="mt-4" disabled={isLoading} onClick={checkTan} >
                        {t("misc.next")}
                        {isLoading ?
                            <span> <Spinner
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /></span> : null
                        }
                    </Button>
                </>
            }
        </>
    );
}

export default Login;