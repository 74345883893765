import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import AuthDataService from "../services/auth.dataservice";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../store/hooks";
import { setLoading } from "../store/slices/loaderSlice";
import Layout from "./Layout";

const EmailVerification: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [verificationMessage, setVerificationMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<"success" | "danger">("danger");
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      dispatch(setLoading(true));
      AuthDataService.verifyEmail(token)
        .then((response) => {
          if (!response.hasError) {
            setVerificationMessage(t("emailVerification.successMessage"));
            setAlertType("success");
          } else {
            setVerificationMessage(t("emailVerification.errorMessage"));
            setAlertType("danger");
          }
        })
        .catch(() => {
          setVerificationMessage(t("emailVerification.errorMessage"));
          setAlertType("danger");
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    } else {
      setVerificationMessage(t("emailVerification.missingTokenMessage"));
      setAlertType("danger");
      dispatch(setLoading(false));
    }
  }, []);

  return (
    <Layout>
      <div className="container-md">
        <div className="row">
          <div className="col-12 pt-4">
            <h1>{t("emailVerification.pageTitle")}</h1>
            <div className={`alert alert-${alertType}`} role="alert">
              {verificationMessage}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EmailVerification;
