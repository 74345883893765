import { RegisterMode } from '../components/Order/Register';
import { AuthenticateRequest } from '../models/AuthenticateRequest';
import { PasswordResetRequest } from '../models/PasswordResetRequest';
import { RegisterRequest } from '../models/RegisterRequest';
import { RequestPasswordResetRequest } from '../models/RequestPasswordResetRequest';

export const hasAnyFormErrors = (item: object) => {
    let hasError = false;
    Object.entries(item).map((property) => {
        if (property[1]) hasError = true;
    })
    return hasError;
}

export const validateLoginForm = (formValues: AuthenticateRequest) => {
    let errors = { email: "", password: "" };
    const errorRequiredMsg = "misc.fieldRequired";
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!formValues.email || !regex.test(formValues.email)) {
        errors.email = "misc.emailRequiredOrWrong"
    }
    return errors;
}

export const validatePasswordResetForm = (formValues: PasswordResetRequest) => {
    let errors = { password: "", passwordRepetition: "" };
    const errorRequiredMsg = "misc.fieldRequired";

    if (!formValues.password) {
        errors.password = errorRequiredMsg
    }
    if (!formValues.passwordRepetition) {
        errors.passwordRepetition = errorRequiredMsg
    }
    return errors;
}

export const validatePasswordResetRequestForm = (formValues: RequestPasswordResetRequest) => {
    let errors = { email: "" };
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!formValues.email || !regex.test(formValues.email)) {
        errors.email = "misc.emailRequiredOrWrong"
    }
    return errors;
}

export const validateRegisterForm = (formValues: RegisterRequest, registerMode: RegisterMode) => {
    let errors = {
        userEmail: "", password: "", companyName: "", companyStreet: "",
        companyPlace: "", companyZip: "", companyCountry: "", salutation: "",
        lastname: "", firstname: "", telephone: "", companyIdNumber: "",
        companyTelephone: "", website: "", email: "", function: "", title: ""
    };
    const maxFieldLengths = {
        userEmail: 50,
        companyName: 100,
        companyStreet: 50,
        companyPlace: 50,
        companyZip: 10,
        companyCountry: 50,
        lastname: 50,
        firstname: 50,
        telephone: 50,
        companyIdNumber: 50,
        companyTelephone: 50,
        website: 100,
        email: 50
    };
    const errorRequiredMsg = "misc.fieldRequired";
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regexIllegalChars = /["{}\\/^'<>;|*]/;
    const regexZipOnlyNumerical = /^[0-9]+$/;
    for (const key in formValues) {
        if (key !== "password" && regexIllegalChars.test(getPropertyByName(formValues, key))) {
            setPropertyByName(errors, key, "misc.fieldContainsIllegalCharacters");
        }
        if (getPropertyByName(formValues, key).length > getPropertyByName(maxFieldLengths, key)) {
            setPropertyByName(errors, key, "misc.fieldValueTooLong");
        }
        if (key === "companyZip" && !regexZipOnlyNumerical.test(getPropertyByName(formValues, key))) {
            setPropertyByName(errors, key, "misc.onlyNumericalAllowed");
        }
    }
    if (registerMode === RegisterMode.EntirelyNewAccount) {
        if (!formValues.userEmail || !regex.test(formValues.userEmail)) {
            errors.userEmail = "misc.emailRequiredOrWrong"
        }
    }
    if (!formValues.companyName) {
        errors.companyName = errorRequiredMsg;
    }
    if (!formValues.companyStreet) {
        errors.companyStreet = errorRequiredMsg;
    }
    if (!formValues.companyPlace) {
        errors.companyPlace = errorRequiredMsg;
    }
    if (!formValues.companyZip) {
        errors.companyZip = errorRequiredMsg;
    }
    if (!formValues.companyCountry) {
        errors.companyCountry = errorRequiredMsg;
    }
    if (!formValues.salutation) {
        errors.salutation = errorRequiredMsg;
    }
    if (!formValues.lastname) {
        errors.lastname = errorRequiredMsg;
    }
    if (!formValues.firstname) {
        errors.firstname = errorRequiredMsg;
    }
    if (!formValues.telephone) {
        errors.telephone = errorRequiredMsg;
    }
    return errors;
}

const getPropertyByName = (obj: any, propertyName: string) => {
    return obj[propertyName];
}

const setPropertyByName = (obj: any, propertyName: string, value: string) => {
    obj[propertyName] = value;
    return obj;
}