import { Routes, Route } from "react-router-dom";
import Order from "./container/Order";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import { Spinner } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "./store/hooks";
import { ReactFragment, Suspense } from "react";
import OrderStatus from "./container/OrderStatus";
import PasswordReset from "./container/PasswordReset";
import EmailVerification from "./container/EmailVerification";

const App: React.FunctionComponent = () => {
  const loader = useAppSelector((rootState) => rootState.loader)

  const renderLoader = (show: boolean): ReactFragment => {
    return (
      <div className={"loader-fullpage " + (show ? "isLoading" : "")}>
        <div className="loader-container">
          <Spinner animation="border"></Spinner>
        </div>
      </div>
    );
  }

  return (
    <Suspense fallback={renderLoader(true)}>
        <Routes>
          <Route path="/" element={<Order />} />
          <Route path="/order/success" element={<OrderStatus isSuccess={true} />} />
          <Route path="/order/failed" element={<OrderStatus isSuccess={false} />} />
          <Route path="/pwreset" element={<PasswordReset />} />
          <Route path="/verify-email" element={<EmailVerification />} />
        </Routes>
        {renderLoader(loader.isLoading)}
    </Suspense>
  );
}

export default App;
