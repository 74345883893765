import { AxiosResponse } from "axios";
import { http } from "../http-common";
import { DiscountCode } from "../models/DiscountCode";
import { Order } from "../models/Order";

class DiscountCodeDataService {
  async get(
    code: string,
    productId: number,
    contactInfo: number,
    order: Order
  ): Promise<AxiosResponse<DiscountCode>> {
    const config = {
      headers: { Authorization: `Bearer ${order.user?.jwtToken}` },
    };
    return await http().get<DiscountCode>(
      `/discountcodes/${code}/product/${productId}/contactInfo/${contactInfo}`,
      config
    );
  }

  async checkForRecurringCustomer(
    contactInfoId: number,
    order: Order
  ): Promise<AxiosResponse<boolean>> {
    const config = {
      headers: { Authorization: `Bearer ${order.user?.jwtToken}` },
    };
    return await http().get<boolean>(
      `/discountcodes/checkForRecurringCustomer/${contactInfoId}`,
      config
    );
  }
}

export default new DiscountCodeDataService();
