import { RegisterMode } from "../components/Order/Register";

export type RegisterRequest = {
    userEmail: string,
    captchaToken: string,
    contactInfoId: number,
    firstname: string,
    lastname: string,
    companyName: string,
    companyIdNumber: string,
    companyStreet: string,
    companyZip: string,
    companyPlace: string,
    companyCountry: string,
    website: string,
    companyTelephone: string,
    salutation: string,
    title: string,
    function: string,
    telephone: string,
    email: string
}

export const defaultRegisterRequest: RegisterRequest = {
    userEmail: "",
    captchaToken: "",
    contactInfoId: 0,
    firstname: "",
    lastname: "",
    companyName: "",
    companyIdNumber: "",
    companyStreet: "",
    companyCountry: "",
    companyPlace: "",
    companyTelephone: "",
    website: "",
    companyZip: "",
    email: "",
    function: "",
    salutation: "",
    telephone: "",
    title: ""
}; 