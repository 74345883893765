import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Loader } from "../../models/Loader";

// Define the initial state using that type
const initialState: Loader = {
  isLoading: true,
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading } = loaderSlice.actions;
export default loaderSlice.reducer;
