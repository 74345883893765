import { faChevronRight, faCheckCircle, faPlusCircle, faTrash, faCircle, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react'
import './ContactInfoSelection.css';
import { Button, Card, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ContactInfo } from '../../models/ContactInfo';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { CarouselStep } from '../../utils/CarouselSteps';
import Register, { RegisterMode } from './Register';
import OrderBackButton from './OrderBackButton';
import { updateOrder } from '../../store/slices/orderSlice';
import contactInfoDataservice from '../../services/contactInfo.dataservice';
import { setError } from '../../store/slices/errorSlice';
import { confirm } from "react-confirm-box";

interface Props { }

export enum ContactInfoSelectionMode {
    SelectExistingOne = 0,
    AddNew = 1,
    Edit = 2
}

const ContactInfoSelection: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const order = useAppSelector((rootState) => rootState.order);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [contactInfoList, setContactInfoList] = useState<ContactInfo[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [mode, setMode] = useState<ContactInfoSelectionMode>(ContactInfoSelectionMode.SelectExistingOne);

    useEffect(() => {
        setMode(ContactInfoSelectionMode.SelectExistingOne);
        loadContactInfos(false);
    }, [order.user?.id]);

    const loadContactInfos = (forceReload: boolean) => {
        if (order.user?.id && (forceReload || mode === ContactInfoSelectionMode.SelectExistingOne)) {
            setIsLoading(true);
            contactInfoDataservice.getAll(order).then((response) => {
                setContactInfoList(response.data);
                if (response.data && response.data.length > 0) {
                    setSelectedIndex(0);
                } else {
                    setSelectedIndex(null);
                }
                dispatch(setError(""));
                setIsLoading(false);
            }).catch((e: Error) => {
                dispatch(setError(t("misc.genericError")));
                setIsLoading(false);
            });
        }
    }

    const onRegisterSuccess = (message: string) => {
        // do nothing
    };

    const onContactInfoSelection = () => {
        if (selectedIndex !== null) {
            let orderState = { ...order };
            orderState.contactInfo = contactInfoList[selectedIndex];
            orderState.carouselIndex = CarouselStep.Discount;
            dispatch(updateOrder(orderState));
        }
    }

    const onEditClick = (index: number) => {
        if (index !== null) {
            let orderState = { ...order };
            orderState.contactInfo = contactInfoList[index];
            dispatch(updateOrder(orderState));
            setMode(ContactInfoSelectionMode.Edit);
        }
    }

    const onDeleteClick = async (id: number | null | undefined) => {
        const options = {
            labels: {
                confirmable: t("misc.yes"),
                cancellable: t("misc.cancel")
            }
        }
        const result = await confirm(t("order.contactInfo.deleteContactInfo"), options);
        if (result) {
            deleteContactInfo(id);
            return;
        }
    };

    const deleteContactInfo = (id: number | null | undefined) => {
        if (id) {
            setIsLoading(true);
            contactInfoDataservice.delete(id, order).then(() => {
                loadContactInfos(true);
            }).catch((e: Error) => {
                setIsLoading(false);
                alert(t("misc.genericError"));
            });
        }
    }

    const onModeChange = (mode: ContactInfoSelectionMode) => {
        setMode(mode);
        loadContactInfos(true);
    }

    const renderHtml = (contactInfoSelectionMode: ContactInfoSelectionMode) => {
        if (contactInfoSelectionMode === ContactInfoSelectionMode.SelectExistingOne) {
            return (
                <>
                    {
                        order.user ? <Alert className='mb-4' variant="success">{t("misc.loggedInAs") + order.user.email}</Alert> : null
                    }
                    <h1>{t("order.contactInfo.chooseContactInfo")}</h1>
                    <h5 dangerouslySetInnerHTML={{ __html: t("order.contactInfo.contactInfoInfo") }} className="mb-4"></h5>

                    <Row className="d-flex justify-content-end">
                        <Button variant="success" onClick={() => setMode(ContactInfoSelectionMode.AddNew)} className="mt-2 mb-3 col-12 col-lg-3">{t("order.contactInfo.addNew")}
                            &nbsp;&nbsp;
                            <FontAwesomeIcon icon={faPlusCircle} /></Button>
                    </Row>

                    {isLoading ?
                        <span> <Spinner
                            animation="border"
                            role="status"
                            aria-hidden="true"
                            className="mb-4 mt-2"
                        /></span> :
                        <Row>
                            {contactInfoList.map((contactInfo, index) => {
                                return (
                                    <Col key={index} lg={4} className="mb-4 contactInfoBox-col">
                                        <Card className={"contactInfoBox " + (selectedIndex !== null && index === selectedIndex ? "selected-box" : "")} onClick={() => setSelectedIndex(index === selectedIndex ? null : index)}>
                                            <Card.Body>
                                                <Card.Title>{contactInfo.companyName}</Card.Title>
                                                <Card.Subtitle><em>{contactInfo.companyIdNumber}</em></Card.Subtitle>
                                                <br />
                                                <Card.Text>
                                                    {contactInfo.title} {contactInfo.firstname} {contactInfo.lastname}<br />
                                                    {contactInfo.companyZip} {contactInfo.companyPlace} <br />
                                                    {contactInfo.companyStreet}<br />
                                                    {contactInfo.companyCountry}<br />
                                                </Card.Text>
                                                <Card.Text className="d-none">
                                                    {contactInfo.website}<br />
                                                    {contactInfo.telephone}<br />
                                                    {contactInfo.email}
                                                </Card.Text>
                                            </Card.Body>
                                            <FontAwesomeIcon className="contactInfoBox-icon-not-checked" fontSize={30} color="white" icon={faCircle} />
                                            <FontAwesomeIcon className="contactInfoBox-icon-checked" fontSize={30} color="green" icon={faCheckCircle} />
                                        </Card>
                                        {
                                            contactInfoList.length > 1 ? <FontAwesomeIcon className="contactInfoBox-icon-delete" fontSize={25} color="#dc3545" icon={faTrash} onClick={() => onDeleteClick(contactInfo.id)} /> : null
                                        }
                                        <FontAwesomeIcon className="contactInfoBox-icon-edit" fontSize={25} color="#000" icon={faEdit} onClick={() => onEditClick(index)} />
                                    </Col>

                                );
                            })}
                        </Row>
                    }

                    <OrderBackButton toIndex={CarouselStep.OrderUser} />
                    {
                        selectedIndex !== null ?
                            <Button variant="primary" onClick={() => onContactInfoSelection()} className="mt-4">
                                {t("misc.next")} <FontAwesomeIcon icon={faChevronRight} />
                            </Button>
                            : null
                    }
                </>
            );
        } else if (contactInfoSelectionMode === ContactInfoSelectionMode.AddNew) {
            return <Register reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ? process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY : ""} onModeChange={onModeChange} registerMode={RegisterMode.OnlyNewContactInfo} contactInfo={null} onRegisterSuccess={onRegisterSuccess} />
        } else if (contactInfoSelectionMode === ContactInfoSelectionMode.Edit) {
            return <Register reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ? process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY : ""} onModeChange={onModeChange} registerMode={RegisterMode.EditContactInfo} contactInfo={order.contactInfo} onRegisterSuccess={onRegisterSuccess} />
        }
    }

    return (
        <div className="row">
            <div className="col-12">
                {renderHtml(mode)}
            </div>
        </div >
    );
}

export default ContactInfoSelection;